<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_105_24)">
      <path
        d="M58 8V38M198 8V38M8 218V68C8 51.4315 21.4315 38 38 38H218C234.569 38 248 51.4315 248 68V218M8 218C8 234.569 21.4315 248 38 248H218C234.569 248 248 234.569 248 218M8 218V118C8 101.431 21.4315 88 38 88H218C234.569 88 248 101.431 248 118V218"
        stroke="currentColor"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_105_24">
        <rect
          width="256"
          height="256"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
